
                  @import "@@/design-tokens/all.scss";
                
.richtextblock {
  font-family: "Stena sans";
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.5px;
  word-break: break-word;

  :global(.sidebar) & {
    /* special overrides for sidebar tags */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
      margin-bottom: 0.5rem;
    }

    blockquote > p,
    blockquote,
    p > blockquote {
      font-size: 16px;
    }

    ul,
    ol,
    p {
      font-size: 16px;
    }

    p {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    ul,
    ol {
      padding-left: 16px;
      margin: 12px 0;

      ul,ol {
        margin: 0 10px;
      }
    }

    pre {
      font-family: inherit;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.5px;
      white-space: initial;

      @media #{$stena-medium-and-down} {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        /* identical to box height, or 136% */

        letter-spacing: 0.5px;
      }

      @media #{$stena-small-and-down} {
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.5px;
      }
    }
  }

  :global {
    @import "./../Button/buttonStyles.module.scss";

    table {
      display: block;
      overflow-x: auto;
      border-spacing: 0;
      height: 100% !important;
      max-width: calc(100vw - 40px);

      thead,
      tbody {
        display: table;
        width: 100%;
      }
    }

    iframe {
      max-width: 100%;
    }

    .text_button {
      background: rgb($white, 0);
      background-image: url("./icons/arrowRight.svg");
      background-size: 16px;
      background-repeat: no-repeat;
      background-position-y: 50%;
      flex-direction: row;
      justify-content: flex-start;
      padding-left: 30px;
      text-align: left;

      &__document {
       background-image: url("./icons/file.svg");
      }

      &:hover {
        color: $shades-blue-01;
        .icon_container {
          background: $shades-blue-01;
        }
      }

      &:active {
        color: $shades-blue-02;
        .icon_container {
          background: $shades-blue-02;
        }
      }
    }

    ul,ol {
      margin: 24px 0;
      padding-left: 4px;

      li > ul,ol {
        margin: 0 26px;
      }
    }

    ul li {
      list-style: disc inside;

      li {
        list-style: circle inside;
        
        li {
          list-style: square inside;
        }
      }
    }

    ol {
      padding-left: 8px;
      li {
        list-style-position: inside;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 1.5rem;
    }

    p {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    blockquote > p,
    blockquote,
    p > blockquote {
      font-weight: normal;
      line-height: 32px;
      letter-spacing: 1.2px;
      font-size: 27px;

      @media #{$stena-medium-and-down} {
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 32px;
        /* or 128% */

        letter-spacing: 0.9px;
      }

      @media #{$stena-small-and-down} {
        font-weight: normal;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.9px;
      }
    }

    h1 {
      font-size: 40px;
      line-height: 45px;
      letter-spacing: 1.2px;

      @media #{$stena-medium-and-down} {
        font-size: 35px;
        line-height: 42px;
      }

      @media #{$stena-small-and-down} {
        font-size: 28px;
        line-height: 32px;
        letter-spacing: 3px;
      }
    }

    h2 {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 3px;

      @media #{$stena-medium-and-down} {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        /* or 115% */

        letter-spacing: 3px;
        text-transform: uppercase;
      }

      @media #{$stena-small-and-down} {
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 3px;
        text-transform: uppercase;
      }
    }

    h3 {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 17px;
      line-height: 23px;
      letter-spacing: 2px;

      @media #{$stena-medium-and-down} {
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 1.8px;
        text-transform: uppercase;
      }

      @media #{$stena-small-and-down} {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 1.8px;
        text-transform: uppercase;
      }
    }

    h4 {
      font-weight: normal;
      font-size: 27px;
      line-height: 32px;
      letter-spacing: 1.2px;

      @media #{$stena-medium-and-down} {
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 32px;
        /* or 128% */

        letter-spacing: 0.9px;
      }

      @media #{$stena-small-and-down} {
        font-weight: normal;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.9px;
      }
    }

    h5 {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 17px;
      line-height: 23px;
      letter-spacing: 2px;

      @media #{$stena-medium-and-down} {
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 1.8px;
        text-transform: uppercase;
      }

      @media #{$stena-small-and-down} {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1.8px;
        text-transform: uppercase;
      }
    }

    a {
      color: $stena-color-info;

      &:hover:not(.base_button) {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    p {
      font-weight: normal;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.5px;

      @media #{$stena-medium-and-down} {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        /* identical to box height, or 144% */

        letter-spacing: 0.5px;
      }

      @media #{$stena-small-and-down} {
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.5px;
      }
    }

    .intro,
    pre {
      font-family: inherit;
      font-weight: normal;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.5px;
      white-space: initial;

      @media #{$stena-medium-and-down} {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 30px;
        /* identical to box height, or 136% */

        letter-spacing: 0.5px;
      }

      @media #{$stena-small-and-down} {
        font-weight: normal;
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 0.5px;
      }
    }

    // COOKIEBOT
    @import "./cookiebot.module.scss";
  }
}
