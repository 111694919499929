@mixin base_button {
  /*
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 2.5px;*/
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 2.5px;
  @media #{$stena-medium-and-down} {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */

    display: flex;
    align-items: center;
    letter-spacing: 2.5px;
    text-transform: uppercase;
  }
  @media #{$stena-small-and-down} {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    width: 100%;
  }

  padding: 10px 24px 8px 24px;
  min-height: 40px;
  min-width: 180px;

  
  @media #{$stena-medium-and-up} { 
    &:not(.text_button) {
      max-width: 250px;
    }
  }
  
  text-decoration: none;
  transition: all 0.3s ease;

  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media #{$stena-medium-and-down} {
    padding: 20px 24px;
    min-height: 56px;
  }
}

//PRIMARY BUTTON

@mixin primary {
  color: $white;
  background: $complementary-blue;
  border: none;

  &:hover {
    background: $shades-blue-01;
  }

  &:active {
    background: $shades-blue-02;
  }

  &:disabled {
    color: $primary-grey-tint-01;
    background: $primary-grey-tint-03;
  }
}

//SECONDARY BUTTON

@mixin secondary {
  border: none;

  &_charcoal {
    color: $white;
    background: $complementary-charcoal;
  }
  &_charcoal:hover {
    background: $complementary-charcoal-tint-01;
  }
  &_charcoal:active {
    background: $shades-charcoal-02;
  }

  &_white {
    color: $complementary-charcoal;
    background: $white;
  }
  &_white:hover {
    background: $primary-grey-tint-03;
  }
  &_white:active {
    background: $primary-grey-tint-03;
  }

  &_white:disabled & {
    color: $primary-grey-tint-01;
    background: $white;
  }
  &_green {
    color: $white;
    background: $primary-green;
  }

  &_green:hover {
    background: $shades-green-01;
  }
  &_green:active {
    background: $shades-green-02;
  }
  .secondary:disabled {
    color: $primary-grey-tint-01;
    background: $primary-grey-tint-03;
  }
}

//TERTIARY BUTTON

@mixin tertiary {
  padding: 7px 23px;
  background: $white;
  border: 1px solid;
  @media #{$stena-medium-and-down} {
    padding: 15px 23px;
  }

  &_blue {
    color: $complementary-blue;
    border-color: $complementary-blue;
  }

  &_blue:hover {
    color: $complementary-blue-tint-01;
    border-color: $complementary-blue-tint-01;
  }
  &_blue:active {
    color: $shades-blue-02;
    border-color: $shades-blue-02;
  }

  &_charcoal {
    color: $complementary-charcoal;
    border-color: $complementary-charcoal;
  }

  &_charcoal:hover {
    color: $complementary-charcoal-tint-02;
    border-color: $complementary-charcoal-tint-02;
  }
  &_charcoal:active {
    color: $shades-charcoal-02;
    border-color: $shades-charcoal-02;
  }
  &_white {
    color: $complementary-charcoal;
    border-color: $white;
  }
  &_white:hover {
    border-color: $primary-grey-tint-03;
  }
  &_white:active {
    border-color: $primary-grey-tint-02;
  }
  &_white:disabled {
    color: $primary-grey-tint-01;
    background: $white;
  }
  &_green {
    color: $primary-green;
    border-color: $primary-green;
  }
  &_green:hover {
    color: $primary-green-tint-01;
    border-color: $primary-green-tint-01;
  }
  &_green:active {
    color: $shades-green-02;
    border-color: $shades-green-02;
  }

  &:disabled {
    color: $primary-grey-tint-01;
    border-color: $primary-grey-tint-03;
  }
}

// TEXT BUTTON WITH ICON

.text_button {
  padding-left: 0px;
  text-decoration: none;
  display: inline-flex;
  background: none;
  border: none;
  align-items: center;
  color: $complementary-blue;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: left;
  &--blue {
    color: $complementary-blue;

    .icon_container {
      background: $complementary-blue;
      color: $white;
    }

    &:hover {
      color: $shades-blue-01;
      .icon_container {
        background: $shades-blue-01;
      }
    }

    &:active {
      color: $shades-blue-02;
      .icon_container {
        background: $shades-blue-02;
      }
    }
  }

  &--charcoal {
    color: $complementary-charcoal;
    .icon_container {
      background: $complementary-charcoal;
      color: $white;
    }

    &:hover {
      color: $complementary-charcoal-tint-01;
      .icon_container {
        background: $complementary-charcoal-tint-01;
      }
    }

    &:active {
      color: $complementary-charcoal-tint-02;
      .icon_container {
        background: $complementary-charcoal-tint-02;
      }
    }
  }

  &--grey {
    color: $complementary-charcoal;
    .icon_container {
      background: $primary-grey;
      color: $white;
    }

    &:hover {
      color: $complementary-charcoal-tint-01;
      .icon_container {
        background: $primary-grey-tint-01;
      }
    }

    &:active {
      color: $complementary-charcoal-tint-02;
      .icon_container {
        background: $complementary-charcoal-tint-02;
      }
    }
  }

  svg {
    height: 100%;
    fill: currentColor;
  }

  &_right {
    flex-direction: row-reverse;
  }

  &_left {
    flex-direction: row;
  }
}

.base_button {
  @include base_button;
  //PRIMARY BUTTON
  &.primary {
    @include primary;
  }
  //SECONDARY BUTTON
  &.secondary {
    @include secondary;
  }

  //TERTIARY BUTTON
  &.tertiary {
    @include tertiary;
  }

  // // TEXT BUTTON WITH ICON
  // &.text_button {
  //   @include text_button;
  // }
}

.icon_container {
  min-height: 40px;
  min-width: 40px;
  border-radius: 100%;
  background: $complementary-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;

  &_right {
    margin-left: 12px;
  }

  &_left {
    margin-right: 12px;
  }
  &_wrapper {
    width: 16px;
    height: 16px;
    display: flex;
    vertical-align: middle;
    justify-content: center;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon__dense {
  min-height: 16px;
  min-width: 16px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &_download {
    height: 20px;
    width: 20px;
    svg {
      fill: none;
    }
  }
  :hover {
    svg {
      fill: red;
    }
  }
}

.button_info {
  min-height: 54px;
  button,
  a {
    height: 100%;
  }
}
