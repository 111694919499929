html {
  color: $complementary-charcoal;
}
.typography {
  font-family: "Stena sans";
  word-break: break-word;
  &--dark {
    color: $complementary-charcoal;
  }
  &--light {
    color: $white;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--italic {
    font-style: italic;
  }

  &--bold {
    font-weight: 700 !important;
  }

  &.hero {
    font-weight: normal;
    font-style: normal;
    font-size: 60px;
    line-height: 66px;
    letter-spacing: 1.2px;
    @media #{$stena-medium-and-down} {
      font-size: 45px;
      line-height: 50px;
    }
    @media #{$stena-small-and-down} {
      font-size: 35px;
      line-height: 40px;
    }
  }
  &.video-hero {
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 66px; /* 110% */
    letter-spacing: 6.6px;
    text-transform: uppercase;

    @media #{$stena-small-and-down} {
      font-feature-settings: "clig" off, "liga" off;
      font-family: Stena Sans;
      font-size: 38px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px; /* 105.263% */
      letter-spacing: 3.4px;
      text-transform: uppercase;
    }
  }

  &.heading1 {
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 46px;
    /* or 110% */

    letter-spacing: 1.2px;
    @media #{$stena-medium-and-down} {
      font-size: 34px;
      line-height: 38px;
    }
    @media #{$stena-small-and-down} {
      font-size: 28px;
      line-height: 32px;
    }
  }

  &.heading2 {
    font-weight: normal;
    font-size: 35px;
    line-height: 40px;
    letter-spacing: 1.2px;
    @media #{$stena-medium-and-down} {
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 34px;
      /* or 112% */

      letter-spacing: 1.2px;
    }
    @media #{$stena-small-and-down} {
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 1.2px;
    }
  }
  &.heading3 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 3px;
    margin-top: 3px;
    @media #{$stena-medium-and-down} {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      /* or 115% */

      letter-spacing: 3px;
      text-transform: uppercase;
    }
    @media #{$stena-small-and-down} {
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 3px;
      text-transform: uppercase;
    }
  }
  &.heading4 {
    font-weight: normal;
    font-size: 27px;
    line-height: 32px;
    letter-spacing: 1.2px;
    @media #{$stena-medium-and-down} {
      font-style: normal;
      font-weight: 400;
      font-size: 25px;
      line-height: 32px;
      /* or 128% */

      letter-spacing: 0.9px;
    }
    @media #{$stena-small-and-down} {
      font-weight: normal;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.9px;
    }
  }
  &.heading5 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 17px;
    line-height: 23px;
    letter-spacing: 2px;

    @media #{$stena-medium-and-down} {
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 1.8px;
      text-transform: uppercase;
    }
    @media #{$stena-small-and-down} {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 1.8px;
      text-transform: uppercase;
    }
  }

  &.heading6 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 23px;
    /* identical to box height, or 164% */

    letter-spacing: 2px;
    text-transform: uppercase;

    @media #{$stena-medium-and-down} {
      font-size: 14px;
      line-height: 16px;
      /* identical to box height, or 114% */

      letter-spacing: 1.8px;
    }
    @media #{$stena-small-and-down} {
      font-size: 14px;
      line-height: 18px;
      /* identical to box height, or 129% */

      letter-spacing: 1.8px;
    }
  }

  &.intro {
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
    @media #{$stena-medium-and-down} {
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 30px;
      /* identical to box height, or 136% */

      letter-spacing: 0.5px;
    }
    @media #{$stena-small-and-down} {
      font-weight: normal;
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0.5px;
    }
  }
  &.body {
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.5px;
    @media #{$stena-medium-and-down} {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      /* identical to box height, or 144% */

      letter-spacing: 0.5px;
    }
    @media #{$stena-small-and-down} {
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.5px;
    }
  }
  &.information {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    @media #{$stena-medium-and-down} {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      letter-spacing: 0.5px;
    }
    @media #{$stena-small-and-down} {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      letter-spacing: 0.5px;
    }
  }
  &.button {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 2.5px;
    @media #{$stena-medium-and-down} {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      /* or 133% */

      display: flex;
      align-items: center;
      letter-spacing: 2.5px;
      text-transform: uppercase;
    }
    @media #{$stena-small-and-down} {
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      letter-spacing: 2.5px;
      text-transform: uppercase;
    }
  }

  &.link {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.9px;
    @media #{$stena-medium-and-down} {
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 13px;
      /* identical to box height, or 118% */

      letter-spacing: 1.9px;
      text-transform: uppercase;
    }
    @media #{$stena-small-and-down} {
      font-weight: bold;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 1.9px;
      text-transform: uppercase;
    }
  }

  &.label {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.5px;
    @media #{$stena-medium-and-down} {
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      /* identical to box height, or 118% */

      letter-spacing: 1.5px;
      text-transform: uppercase;
    }
    @media #{$stena-small-and-down} {
      font-weight: normal;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }
  }

  &.pretitle {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 2.5px;
    @media #{$stena-medium-and-down} {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      /* identical to box height, or 114% */

      letter-spacing: 2.5px;
      text-transform: uppercase;
    }
    @media #{$stena-small-and-down} {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 2.5px;
      text-transform: uppercase;
    }
  }
  &.menu {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 2.5px;
    @media #{$stena-medium-and-down} {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      /* identical to box height, or 114% */

      letter-spacing: 2.5px;
      text-transform: uppercase;
    }
    @media #{$stena-small-and-down} {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 2.5px;
      text-transform: uppercase;
    }
  }

  &.submenu1 {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.5px;
    @media #{$stena-medium-and-down} {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
      /* identical to box height, or 188% */

      letter-spacing: 0.5px;
    }
    @media #{$stena-small-and-down} {
      font-weight: bold;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 0.5px;
    }
  }
  &.submenu2 {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;

    letter-spacing: 0.5px;
    @media #{$stena-medium-and-down} {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height, or 119% */

      letter-spacing: 0.5px;
    }
    @media #{$stena-small-and-down} {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.5px;
    }
  }
  &.toolbarMenu {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    @media #{$stena-medium-and-down} {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      /* identical to box height, or 133% */

      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
    @media #{$stena-small-and-down} {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
  }
}
