@mixin gutter-settings($bp) {
  &--#{$bp}-gx-0 {
    --container-gutter-x-size: 0px;
  }
  &--#{$bp}-gx-3 {
    --container-gutter-x-size: 12px;
  }
  &--#{$bp}-gx-5 {
    --container-gutter-x-size: 20px;
  }
  &--#{$bp}-gx-10 {
    --container-gutter-x-size: 40px;
  }
  &--#{$bp}-gx-15 {
    --container-gutter-x-size: 60px;
  }
  &--#{$bp}-gx-20 {
    --container-gutter-x-size: 80px;
  }
  &--#{$bp}-gx-7-5p {
    --container-gutter-x-size: 7.5vw;
  }

  &--#{$bp}-gy-0 {
    --container-gutter-y-size: 0px;
  }
  &--#{$bp}-gy-3 {
    --container-gutter-y-size: 12px;
  }
  &--#{$bp}-gy-5 {
    --container-gutter-y-size: 20px;
  }
  &--#{$bp}-gy-10 {
    --container-gutter-y-size: 40px;
  }
  &--#{$bp}-gy-15 {
    --container-gutter-y-size: 60px;
  }
  &--#{$bp}-gy-20 {
    --container-gutter-y-size: 80px;
  }
  &--#{$bp}-gy-7-5p {
    --container-gutter-y-size: 7.5vw;
  }
}

@mixin size-settings($bp) {
  &--#{$bp}-size-xsmall {
    --container-max-width: 504px;
  }
  &--#{$bp}-size-small {
    --container-max-width: 680px;
  }
  &--#{$bp}-size-medium {
    --container-max-width: 767px;
  }
  &--#{$bp}-size-large {
    --container-max-width: 920px;
  }
  &--#{$bp}-size-xlarge {
    --container-max-width: 1920px;
  }
  &--#{$bp}-size-full {
    --container-max-width: 100%;
  }
}