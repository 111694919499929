
                  @import "@@/design-tokens/all.scss";
                
$border-radius: 5px;
$animation-time: 0.3s;

.accordion {
  border-radius: $border-radius;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
}
.accordion_top_section {
  .accordion_heading {
    border-radius: $border-radius;
    .heading {
      white-space: normal;
    }
  }
}

.accordion_heading {
  .heading {
    padding-right: 16px;
  }
}

.accordion_content {
  max-height: 0;
  overflow-y: hidden;
  -webkit-transition: max-height $animation-time ease-in-out;
  -moz-transition: max-height $animation-time ease-in-out;
  -o-transition: max-height $animation-time ease-in-out;
  transition: max-height $animation-time ease-in-out;
  > div:first-child {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  @media #{$stena-medium-and-down} {
    max-height: 5000px;
  }

  &__open {
    max-height: 5000px;
    @media #{$stena-medium-and-down} {
      max-height: 0;
    }
  }

  &__closed_on_load {
    max-height: 0px;
      

    &__open {
      max-height: 5000px;
    }
  }
}

.accordion_icon {
  height: 12px;
  transform: rotate(180deg);

  transition-duration: $animation-time;
  transition-property: transform;

  @media #{$stena-medium-and-down} {
    transform: rotate(360deg);
  }

  &__open {
    transform: rotate(360deg);
    @media #{$stena-medium-and-down} {
      transform: rotate(180deg);
    }
  }

  &__closed_on_load {
    transform: rotate(180deg);

  &__open {
    transform: rotate(360deg);
  }
}
}

.accordion_divider {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-top: 22px;
  margin-bottom: 22px;
}

.accordion_downloads__column {
  width: 100%;
  max-width: 130px;
  min-width: 130px;
}

.accordion_downloads__link_wrapper {
  display: flex;
  a {
    align-items: flex-end;
    :last-child {
      line-height: 1;
    }
  }
}

.accordion_richtext__button {
  margin-bottom: 0;
}

.sizes_list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.materials {
  padding: 4px 8px;
  margin: 0 4px 4px 0;
  white-space: nowrap;
  max-width: calc(100vw - 100px);
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.accessories {
  max-width: 177px;
  @media #{$stena-small-and-down} {
    max-width: none;
  }
}
