
                  @import "@@/design-tokens/all.scss";
                
.faq {
  &__minusicon {
    background-color: $primary-grey-tint-03;
    border: 1px solid black;
  }

  &__container {
    &_grey {
      background: $primary-grey-tint-03;
      background: linear-gradient(
        180deg,
        $primary-grey-tint-03 0%,
        rgb($primary-grey-tint-03, 0%) 50%
      );
    }
    &_white {
      background: $white;
    }
  }

  &--showall {
    & .faq__item {
      display: block;
    }
  }
  &__item {
    display: block;
    &--hidden {
      display: none;
    }
  }
}
